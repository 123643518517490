import React from 'react'
import Layout from "../../components/Layout"
import * as contactStyles from '../../stylesheets/pages/contact.module.scss'
import * as helpers from '../../stylesheets/abstracts/helpers.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { animateIntro } from '../../gsap/contact'

export default function Contact({ data }) {
    let mainHeader = React.useRef(null)
    let mainText = React.useRef(null)
    let form = React.useRef(null)

    React.useEffect(() => {
        animateIntro(mainText, mainHeader, form)
    }, [])

    return (
        <Layout>
        <div className={contactStyles.container}>
            <div className={contactStyles.textContainer}>
                <h2 ref={el => (mainHeader = el) } >Let's <br className={helpers.pcOnly} />Chat...</h2>
                <p ref={el => (mainText = el) } >Don't be shy! Send a message and I'll be in touch soon!</p>
            </div>
            <div ref={el => (form = el) } className={contactStyles.formContainer}>
                <form className={contactStyles.formControl} method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <p>
                        <input required placeholder="Name" className={contactStyles.formField} type="text" name="name" />
                    </p>
                    <p>
                        <input required placeholder="Email" className={contactStyles.formField} type="email" name="email" />
                    </p>
                    <p>
                       <textarea required placeholder="Your message" className={contactStyles.formFieldTextarea} name="message"></textarea>
                    </p>
                    <p>
                        <button className={contactStyles.formSubmit} type="submit">
                            Send
                            <FontAwesomeIcon  className={contactStyles.formSendIcon} icon={faPaperPlane} />
                        </button>
                    </p>
                </form>
            </div>
        </div>
        </Layout>
    )
}
