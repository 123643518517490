import gsap from 'gsap'

export function animateIntro (mainText, mainHeader, form) {
    gsap.to(mainHeader, { x: 0, delay: 0.1})
    gsap.to(mainText, { x: 0, delay: 0.2})
    gsap.to (form, { 
        x: 0, 
        y: 0,
        duration: 1.5,
        ease: "easeInOut"
    })
}